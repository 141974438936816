import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCardHome from '../components/blogCardHome'
import CustomReactShare from '../components/customShareBlock'
import HomeHeader from '../components/homeHeader'
import AboutJulia from '../components/aboutJulia'
import mobileBackground from '../../static/realBackground.jpg'
import desktopBackground from '../../static/newHomeBackground.jpg'
import Loader from '../components/loader'
import ComingSoon from '../components/comingSoon'

import firebase from '../services/firebase';
import 'firebase/database';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
})
)

const BlogIndex = ({ data, location }) => {
  const classes = useStyles()

  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const image = data.site.siteMetadata.siteImage
  const url = data.site.siteMetadata.siteUrl
  const description = data.site.siteMetadata.description
  const imageObject = { "src": image, "height": 400, "width": 1000 }

  const [loadingIndicator, setLoadingIndicator] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
      } else {
        // No user is signed in.
        firebase.auth().signInAnonymously().catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });
      }
    });
  }, [])

  return (
    <div>
      {loadingIndicator &&
        <Loader load={loadingIndicator} setLoad={setLoadingIndicator} />
      }
      <div style={
        {
          display: loadingIndicator ? 'none' : 'block'
        }
      }>
        {/* <ComingSoon /> */}
        <div className={classes.desktop} >
          <HomeHeader pic={desktopBackground} text='' />
        </div>
        <div className={classes.mobile} >
          <HomeHeader pic={mobileBackground} text='' />
        </div>
        <Layout location={location} title={siteTitle}>
          <SEO title={siteTitle}
            image={imageObject}
            description={description}
            pathname={location.pathname} />
          <AboutJulia />
          <BlogCardHome posts={posts} />
          <div>
            <h4
              style={
                {
                  textAlign: 'center',
                  justifyContent: 'center',
                  paddingBottom: '.1%'
                }
              }>
              Share this Website
                <CustomReactShare title={siteTitle} excerpt={description} url={url} text='#blog via @ailuj876' />
            </h4>
          </div>
        </Layout>
      </div>
    </div>
  )
}

export default BlogIndex

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      siteImage
      description
      siteUrl
    }
  }
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
  edges {
    node {
      excerpt
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        link
        buttonText
        image: featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        tags
      }
    }
  }
}
}
`
