/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { makeStyles } from '@material-ui/core/styles';

import { rhythm } from "../utils/typography"

const useStyles = makeStyles((theme) => ({
  aboutImage: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  }
})
)

const AboutJulia = () => {

  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query AboutQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          bio
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social, bio } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(.5),
        paddingRight: '2%'
      }}
    >
      <div className={classes.aboutImage}>
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author.name}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 65,
            minHeight: 65,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      </div>
      <p
        style={
          {
            fontSize: '18px'
          }
        }
      >
        <strong>Hi! I am {author.name}.</strong> {bio}
        {` `}
        Follow me on
        {` `}
        <a href={`https://twitter.com/ailuj876`} target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        {` `}
        and check out my
        {` `}
        <a href={'https://www.youtube.com/channel/UC3p1LrTRYhvE2YitRm-kEig?sub_confirmation=1'} target="_blank" rel="noopener noreferrer">
          YouTube Channel.
        </a>
      </p>
    </div>
  )
}

export default AboutJulia
